import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Fade } from "react-awesome-reveal";
import { Link } from "gatsby"

const NotFoundPage = () => {

  return (
    <Layout classes="not-found">
      <Seo title="404: Not found" />
      <section className="hero">
        <div className="container">
          <Fade triggerOnce>
            <p className="small">Oh No!</p>
            <h1>404: Page Not Found</h1>
            <p>Feel free to visit one of the following pages:</p>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/for-lawyers/">For Lawyers</Link></li>
              <li><Link to="/for-judges/">For Judges</Link></li>
              <li><Link to="/the-project/">The Project</Link></li>
              <li><Link to="/research-team/">Research Team</Link></li>
              <li><Link to="/news-&-events/">News &amp; Events</Link></li>
              <li><Link to="/get-help/">Get Help</Link></li>
            </ul>
          </Fade>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
